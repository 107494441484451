<template>
  <div class="class-container">
    <div class="content">
      <div class="search-box">
        <Row class="search-row">
          <Col span="4">
            <span class="sub-label">课程活动：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.kchd_id"
                      placeholder="请选择...">
                <Option :value="v.id"
                        v-for="v in cityList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">活动日期：</span>
            <div class="sub-content">
              <DatePicker
                type="daterange"
                placeholder="请选择..."
                style="width: 100%"
                @on-change="handleDateChange"></DatePicker>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">主讲人：</span>
            <div class="sub-content">
              <Input v-model="searchData.keyword" placeholder="请输入主讲人姓名"></Input>
            </div>
          </Col>
          <Col span="4" offset="1">
            <Button type="primary" @click="handleSearch">搜索</Button>
          </Col>
        </Row>
      </div>
      <div class="content-body">
        <Table
          :columns="classColumns"
          :data="classList"
          ref="table"></Table>
          <div>
            <span class="records">共 {{totalClasses}} 条记录</span>
          </div>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="totalClasses"
                :current="searchData.page"
                :page-size="searchData.size"
                @on-change="changeDataPage"></Page>
            </div>
          </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="课程活动详情"
      class-name="vertical-center-modal class-page"
      width="800">
      <Form :model="formData" :label-width="110">
        <Row class="search-row">
          <Col span="11">
            <Form-item label="活动日期">
              <span class="text">{{ formData.hdrq }}</span>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="活动门店">
              <span class="text">{{ formData.hddd }}</span>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="课程活动">
              <span class="text">{{ formData.kchd_name }}</span>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="登记时间">
              <span class="text">{{ formData.djsj }}</span>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="主讲人">
              <span class="text">{{ formData.zjr }}</span>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="登记人">
              <span class="text">{{ formData.djr_name }}</span>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="活动主题">
              <span class="text">{{ formData.hdzt }}</span>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="活动时长">
              <span class="text">{{ formData.hdsc }}</span>
            </Form-item>
          </Col>
        </Row>
        <Form-item label="活动简介">
          <span class="text">{{ formData.jj }}</span>
        </Form-item>
        <Form-item label="活动图片">
          <div class="demo-upload-list" v-for="item in uploadList" :key="item.id">
            <img :src="item.url">
          </div>
        </Form-item>
        <Form-item label="复健仪器">
          <span class="text">{{ formData.fjyq_list.map(item => item.name).join('、') }}</span>
        </Form-item>
      </Form>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';

export default {
	data() {
		return {
			storeList: [],
			proList: [],
			cityList: [],
			searchData: {
				page: 1,
				size: 1000,
				keyword: '',
				kchd_id: '',
				start_time: '',
				end_time: '',
			},
			totalClasses: 0,
			selectedDataItem: [],
			classList: [],
			classColumns: [
				{
					title: '活动时间',
					align: 'center',
					render(h, params) {
						return h('span', params.row.hdrq.substring(0, 16));
					},
				},
				{
					title: '录入时间',
					align: 'center',
					render(h, params) {
						return h('span', params.row.create_time.substring(0, 16));
					},
				},
				{ title: '课程类别', key: 'kchd_name', align: 'center' },
				{ title: '活动主题', key: 'hdzt', align: 'center' },
				{ title: '主讲人/组织人', key: 'zjr', align: 'center' },
				{ title: '活动地址', key: 'hddd', align: 'center' },
				{ title: '登记人', key: 'djr_name', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h('a', {
							class: 'icon_eye',
							style: { marginRight: 0 },
							on: {
								click: () => {
									memberService.getClass({ record_id: params.row.id }).then((data) => {
										this.formData = data;
										this.uploadList = data.tp;
									});
									this.showModal = true;
								},
							},
						});
					},
				},
			],
			showModal: false,
			formData: {
				kchd: '',
				hdzt: '',
				hdrq: '',
				zjr: '',
				hdsc: '',
				hddd: '',
				djr_name: '',
				djsj: '',
				jj: '',
				fjyq_list: [],
			},
			uploadList: [],
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
		this.handleSearch();
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		changeDataPage(page) {
			this.searchData.page = page;
			this.handleSearch();
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection.map((item) => item.id);
		},
		handleSearch() {
			this.searchData.member_id = this.$route.params.member_id;
			memberService.getClassList(this.searchData).then((data) => {
				this.classList = data.list;
				this.totalClasses = data.row_size;
			});
		},
		handleDateChange(val) {
			if (val.every((item) => item)) {
				this.searchData.start_time = val[0];
				this.searchData.end_time = val[1];
			} else {
				this.searchData.start_time = null;
				this.searchData.end_time = null;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content {
  background-color: #fff;
}
.search-box {
  padding-bottom: 10px;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.sub-content {
  margin-left: 80px;
}
.demo-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin-right: 4px;
  vertical-align: middle;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.text {
  line-height: 34px;
}
</style>
